import React from 'react';
import {useStaticQuery, graphql, Link} from 'gatsby';
import ProductCarousel from '../components/ProductCarousel'
import homeBorder from '../assets/homeBorder.svg'
export default () => {
  const data = useStaticQuery(graphql`
  query HomeQuery {
    mission: graphCmsTextContent(identifier: {eq: "mission"}) {
      identifier
      content
    }
    vision: graphCmsTextContent(identifier: {eq: "vision"}) {
      identifier
      content
    }
  }
  
  `)
  return (
    <>
      <ProductCarousel styles='hidden lg:block' slides={4}/>
      <ProductCarousel styles='hidden md:block lg:hidden ' slides={3}/>
      <ProductCarousel styles='block md:hidden' slides={1}/>
      <div className='flex flex-col lg:flex-row '>
        <div className='flex flex-col lg:w-3/5 p-12'>
          <div className='flex flex-col font-mont items-center lg:items-baseline'>
            <p className='font-bold text-3xl'>Our Mission</p>
            <p className='p-4 max-w-xl'>{data.mission.content}</p>
          </div>
          <hr style={{border:'1px solid #DADADA'}} className='my-5  '/>
          <div className='flex flex-col font-mont  items-center lg:items-baseline'>
            <p className='font-bold text-3xl'>Our Vision</p>
            <p className='p-4 max-w-xl'>{data.vision.content}</p>
          </div>
        
        </div>
        


        <div className='flex flex-col lg:w-2/5 text-xl text-white font-semibold font-mont relative'>
          <img draggable='false' src={homeBorder} className={`hidden lg:block absolute h-full z-50 -ml-2 p-0 `}/>
          <Link className='h-1/3 py-10 w-full bg-yellow-base  hover:bg-yellow-hoverBase shadow z-20 flex items-center justify-center lg:justify-end lg:pr-20 ' to='/about'>
            More About Us
          </Link>
          <Link className='h-1/3 py-10 w-full bg-yellow-darker hover:bg-yellow-hoverDarker shadow z-10 flex items-center justify-center lg:justify-end lg:pr-20' to='/products'>
            View All Our Products
          </Link>
          <Link className='h-1/3 py-10 w-full bg-yellow-darkest hover:bg-yellow-hoverDarkest z-0 flex items-center justify-center lg:justify-end lg:pr-20' to='/contact'>
            Contact Us Today
          </Link>
        </div>
        
      </div>
    </>
  )
}