import React from 'react';
import {navigate} from 'gatsby';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faHome, faLock, faBandAid, faAmbulance, faCarCrash, faGavel, faTools, faShip, faChartLine, faCashRegister, faPlaneDeparture} from "@fortawesome/free-solid-svg-icons";
import BackgroundImage from 'gatsby-background-image-es5';

const iconMap = {
    "property": faHome,
    "crime": faLock,
    "health-medical": faBandAid,
    "personal-accident": faAmbulance,
    "motor-insurance": faCarCrash,
    "liability-insurance": faGavel,
    "engineering-insurance": faTools,
    "marine-insurance":faShip,
    "trade-credit-insurance": faChartLine,
    'sme-package': faCashRegister,
    "travel-insurance": faPlaneDeparture
}

export default (props)=>{
    const {slug, productTitle, pictureTall} = props.node;

    return(
        <BackgroundImage
            className='h-full'
            Tag="section"
            fluid={pictureTall.localFile.childImageSharp.fluid}
            style={{
                backgroundPosition:'center'
            }}
            backgroundColor={`#fff`}>
        <div className='w-full h-full bg-black bg-opacity-50 text-white  hover:bg-black hover:bg-opacity-80'>
            <div className='flex flex-col h-full items-center justify-center p-10 text-center '  >
                <div onClick={()=> navigate(`/products/${slug}`)} className='cursor-pointer'>
                <FontAwesomeIcon className='text-5xl m-5' icon={iconMap[slug]}/>
                <p className='text-2xl font-bold font-mont' >{productTitle}</p>
                </div>
            </div>
        </div>
        </BackgroundImage>
    )
}


