import React from 'react'
import {useStaticQuery, graphql} from 'gatsby'
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import CarouselDiv from './CarouselDiv';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faChevronCircleRight, faChevronCircleLeft} from '@fortawesome/free-solid-svg-icons'

export default (props)=>{
    const {allGraphCmsProduct:{nodes}} = useStaticQuery(graphql`
    query CarouselQuery {  
        allGraphCmsProduct{
            nodes {
                id
                productTitle
                slug
                pictureTall {
                  localFile {
                    childImageSharp {
                        fluid(maxWidth:1920,quality:90) {
                          ...GatsbyImageSharpFluid
                        }
                    }
                  }
                }
                
            }
        }
    }`)

    return (
        <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={100}
        totalSlides={nodes.length}
        visibleSlides={props.slides}
        infinite={true}
        interval={4500}
        step={props.slides}
        dragStep={props.slides}
        className={`${props.styles} h-full relative`}
        >
        
        <div className='relative'>
        <Slider>
          {nodes.map((node, i)=>
              <Slide index={i}>
                  <CarouselDiv node={node}/>
              </Slide>
          )}
          
        </Slider>
        
        </div>
        <ButtonBack className='absolute text-white z-50 left-0 top-1/2'>
          <FontAwesomeIcon className='mx-3 text-3xl shadow-xl opacity-50' icon={faChevronCircleLeft}/>
        </ButtonBack>
        <ButtonNext className='absolute text-white z-50 right-0 top-1/2'>
          <FontAwesomeIcon className='mx-3 text-3xl shadow-xl opacity-50' icon={faChevronCircleRight}/>
        </ButtonNext>
        
        </CarouselProvider>
    )
}